html,
body {
  min-width: 768px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 2em white inset !important;
}

.nz-table-hide-scrollbar {
  scrollbar-width: thin; /* 0.75em */
}

::-webkit-scrollbar-track {
  border-radius: 0.75em;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 0.75em;
  height: 0.75em;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.75em;
  -webkit-box-shadow: inset 0 0 0.5em rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.monospace {
  font-family: monospace;
}

.hidden {
  display: none !important;
}

.table-cell__nowrap {
  white-space: nowrap;
}

.text-info {
  color: @primary-color;

  &:hover,
  &:focus {
    color: @primary-color-hover;
  }
}

a {
  color: #2196f3;
}

.text-danger {
  color: @error-color;

  &:hover,
  &:focus {
    color: @error-color-hover;
  }
}

.text-success {
  color: @success-color;

  &:hover,
  &:focus {
    color: @success-color-hover;
  }
}

.text-black {
  color: rgba(0, 0, 0, 0.85);

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.55);
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

button + button {
  margin-left: 1em;
}

.ant-breadcrumb a {
  color: #000;
}

.text-hover {
  cursor: pointer;
}

.ant-empty-image {
  height: 64px;
}

.ant-layout-sider-children {
  height: calc(100vh - 3.5rem);
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 0.25em;
    background-color: #3f4c5c;
  }

  &::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
    background-color: #3f4c5c;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25em;
    -webkit-box-shadow: inset 0 0 0.5em rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}
