.block {
  background: #fff;
  margin: 0 0 1em 0;
  position: relative;
}

.block-header {
  font-size: 1.25em;
  padding: 0.5em 1em;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  font-weight: 500;

  collapse-button {
    margin-right: 0.5rem;
  }
}

.block-body {
  padding: 1em;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.pb-0 {
  padding-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1em;
}

.mr-1 {
  margin-right: 1em;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-grow-1 {
  flex-grow: 1;
}
