.journal-action {
  text-align: left;
  position: relative;
  top: -0.5em;
  display: flex;

  .ant-btn-icon-only {
    color: @primary-color;
    border-color: @primary-color;

    &:hover,
    &:focus {
      color: #fff;
      background: @primary-color;
    }
  }

  & button i {
    font-size: 1em;
  }

  & button + button {
    margin-left: 0.5em;
  }
}

.journal-footer {
  position: absolute;
  bottom: 2em;
}

.journal-action-prepend {
  margin-right: 0.5em;
}

.journal-action-append {
  margin-left: 0.5em;
}
