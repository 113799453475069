.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  padding: 0.3em 0.5em;
}

.action-column {
  white-space: nowrap;
  text-align: right;
}

.action-column i {
  font-size: 1.5em;
  margin-right: 0.4em;
  position: relative;
  top: 0.1em;
}

.grid-container {
  position: relative;
}

.grid-container__action {
  text-align: right;
  position: absolute;
  right: 0;
  top: -0.75em;
  z-index: 2;
}

.ant-table-column-sorters {
  padding: 0.25em 0.75em;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: @primary-color;
}

.ant-table-expanded-row-fixed {
  min-width: 100%;
}

.table-column_nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
