.ant-progress-text {
  position: absolute;
  left: 50%;
  top: 0.4em;
  color: #333;
  margin: 0;
  font-weight: 500;
}

.ant-progress-show-info .ant-progress-outer {
  padding-right: 0;
}
