.rc {
  fill: #ffffff;
  fill-opacity: 1;
  fill-rule: nonzero;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-opacity: 1;
  pointer-events: all;
}

.r_hover {
  cursor: pointer;
}

.r_selected .rc {
  stroke: #2f80e7;
}
