.form-container {
  max-width: 800px;
}

.form-container_xl {
  max-width: 1200px;
}

.form-group {
  margin: 0 0 1em 0;
}

.form-group_empty {
  margin: 0 0 0.25em 0;
}

.form-group__action {
  background: #fff;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  z-index: 2;
  width: calc(100% - 256px);
  padding: 15px 20px;
  box-shadow: 0px -6px 8px -5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;

  .form-group {
    margin: 0;
  }

  .form-group__error {
    clear: both;
    padding-left: 20px;
    min-height: 24px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding-top: 0;
  }
}

.ant-layout-sider-collapsed {
  .form-group__action {
    width: calc(100% - 80px);
  }
}

.ant-form-item-label > label {
  white-space: normal;
  height: auto;
  min-height: 32px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 3px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 360px;
  height: 225px;
}

.image-upload {
  & .ant-upload {
    overflow: hidden;
  }
}

.image-upload__action {
  position: absolute;
}

.image-upload_preview {
  & .image-upload__action {
    opacity: 0;
    color: #fff;
    z-index: 3;
  }

  & img {
    object-fit: cover;
  }

  & > .ant-upload {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s;
      content: ' ';
    }

    &:hover,
    &:focus {
      &:before {
        opacity: 1;
      }

      & .image-upload__action {
        opacity: 1;
      }
    }
  }
}

.ant-upload-list-picture {
  .ant-upload-list-item-name {
    padding: 0 8px 0 0;
  }

  .ant-upload-list-item-progress {
    width: calc(100% - 48px);
    bottom: 10px;
  }

  .ant-upload-list-item-card-actions-btn i {
    font-size: 20px !important;
  }
}

.ant-input[readonly] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.control-label_one-line label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & i {
    position: relative;
    top: 4px;
  }
}

.control-label_one-line:hover {
  position: relative;
  height: 25px;
  display: block;
  overflow: visible;

  label {
    overflow: visible;
    width: auto;
    white-space: normal;
    word-break: break-word;
    z-index: 5;
    position: absolute;
    background: #fff;
  }
}

form nz-input-number {
  min-width: 150px !important;
}

.ant-input-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1;
}

nz-code-editor {
  border: 1px solid #ccc;
}

.reference-content {
  max-height: 100%;
}

.reference-modal {
  .ant-modal-content {
    overflow-y: auto;
    height: 90vh;
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  nz-journal {
    margin-bottom: 0;
  }

  .ant-modal-footer {
    position: sticky;
    bottom: 0;
    background: #fff;
  }
}

.ant-radio-checked .ant-radio-inner {
  box-shadow: none !important;
}
