.form-list {
  margin: 0 0 16px 0;

  nz-form-item {
    margin: 0;
  }
}

.form-list__body .ant-col {
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-list__header {
  margin: 0 0 0.5rem 0;
}

.form-list__remove {
  & a {
    line-height: 2.25em;
  }

  & i {
    font-size: 1.25em;
    line-height: 1.25em;
  }
}
